<template>
    <div class="page-style">
        <div class=" mt30 pl50 fz28 f333 ">订单信息</div>
        <div class="block_base mt15 pb20">
            <div class="flex_m pl20 pr20 pt20">
                <span class="pr20 f888">仓库信息</span>
                <div class="flex1 tr f333">{{parmsJson.depotName || ''}}
                </div>
            </div>
            <template v-if="type == 'in'">
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">预计入库时间</span>
                    <div class="flex1 tr f333">{{parmsJson.warehousingTime}}</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">储存天数</span>
                    <div class="flex1 tr f333">{{parmsJson.storageDays}}天</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">入库需求</span>
                    <div class="flex1 tr f333">{{parmsJson.warehousingType ? warehousTypeArr[parmsJson.warehousingType -
                        1].text : ''}}</div>
                </div>
            </template>
            <template v-else>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">预计提货时间</span>
                    <div class="flex1 tr f333">{{parmsJson.takeTime }}</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">出库方式</span>
                    <div class="flex1 tr f333">{{getName(parmsJson.checkoutType,outTypeArr)}}</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">付款方式</span>
                    <div class="flex1 tr f333">{{getName(parmsJson.paymentType,outPayArr)}}</div>
                </div>
            </template>
        </div>
        <template v-if="type == 'out'">
            <div class=" mt30 pl50 fz28 f333 ">物流信息</div>
            <div class="block_base mt15 pb20">
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">配送方式</span>
                    <div class="flex1 tr f333">{{getName(parmsJson.deliveryType,outTalkArr)}}</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">提货司机</span>
                    <div class="flex1 tr f333">{{parmsJson.driverName}}</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">联系方式</span>
                    <div class="flex1 tr f333">{{parmsJson.driverPhone}}</div>
                </div>
                <div class="flex_m pl20 pr20 pt20">
                    <span class="pr20 f888">车牌号</span>
                    <div class="flex1 tr f333">{{parmsJson.licenseNumber}}</div>
                </div>
            </div>
        </template>

        <template v-if="parmsJson.commodityJson && parmsJson.commodityJson.length">
            <div class=" mt30 pl50 fz28 f333 "> {{type == 'in' ? '入库清单':'商品明细'}}</div>
            <div class="mt20 pl20 pr20">
                <div class="t-tr bg-w">
                    <div :class="['th','w'+thIdx]" v-for="(item,thIdx) in (type == 'in' ? entryTable : outTable)">
                        {{item.th}}</div>
                </div>
                <div class="t-tr" v-for="(item,index) in parmsJson.commodityJson">
                    <div :class="['td','w'+thIdx]" v-for="(th,thIdx) in (type == 'in' ? entryTable : outTable)">
                        {{item[th.td]}}</div>
                </div>
            </div>
            <div class="h100 pl20 pr20">
                <div class="f888 fz26 tr pt20">合计：<span
                        class="icon_m">{{parmsJson.totalPakages}}箱、{{parmsJson.totalWeight}}kg、{{parmsJson.totalVolume}}方</span>
                </div>
            </div>
        </template>

        <div class=" mt30 pl50 fz28 f333 ">费用明细</div>
        <!--租仓费-->
        <div class="block_base mt30 pl20 pr20">
            <div class="flex_m plt25 lh60">
                <div>租仓费</div>
                <div v-if="servicesPrice.ZCFPrice>0" class="flex_autosize ml20 flex_end ellipsis">
                    {{servicesPrice.ZCFPrice}}元</div>
                <div v-if="servicesPrice2 && servicesPrice2.feeValueZCF" class="flex_autosize ml20 flex_end ellipsis">
                    单价：{{servicesPrice2.sellRateZCF}}元/{{unitFun(servicesPrice2.unitZCF) || ''}}</div>
            </div>
            <template v-if="servicesPrice.ZCFPrice>0" v-for="item in servicesPrice.ZCF">
                <div class="flex_m plt25 border_t lh60">
                    <div class="flex1 ellipsis mr20">
                        <span class="w150 tr fz26">商品名称：</span>
                        {{item.commodityName}}
                    </div>
                    <div class="flex1 ellipsis">
                        <span class="w150 tr fz26">入库日期：</span>
                        {{moment(item.warehousingTime).format('YYYYMMDD')}}
                    </div>
                </div>
                <div class="flex_m plt25 lh60">
                    <div v-if="type == 'out'" class="flex1 ellipsis mr20">
                        <span class="w150 tr fz26">出库日期：</span>
                        {{moment(item.takeTime).format('YYYYMMDD')}}
                    </div>
                    <div class="flex1 ellipsis">
                        <span class="w150 tr fz26">天数：</span>
                        {{item.storageDays}}
                    </div>
                </div>
                <div class="flex_m plt25 lh60">
                    <div class="flex1 ellipsis  mr20">
                        <span class="w150 tr fz26">托盘数：</span>
                        {{item.palletNumber}}
                    </div>
                    <div class="flex1 ellipsis">
                        <span class="w150 tr fz26">单价：</span>
                        {{item.sellRate}}元
                    </div>
                </div>
            </template>
        </div>
        <!--装卸费-->
        <div class="block_base mt30 pl20 pr20">
            <div class="flex_m plt25 lh60">
                <div>装卸费</div>
                <div v-if="servicesPrice.ZXFPrice>0" class="flex_autosize ml20 flex_end ellipsis">
                    {{servicesPrice.ZXFPrice}}元</div>
                <div v-if="servicesPrice2 && servicesPrice2.feeValueZXF" class="flex_autosize ml20 flex_end ellipsis">
                    单价：{{servicesPrice2.sellRateZXF}}元/{{unitFun(servicesPrice2.unitZXF)||''}}</div>
                <template v-for="item in servicesPrice.ZXF">
                    <view class="flex_m plt25 border_t lh60">
                        <view class="flex1 ellipsis mr20">
                            <text class="w150 tr fz26">{{ unitFun(item.unit) || ''}}数：</text>
                            {{item.number}}
                        </view>
                        <view class="flex1 ellipsis">
                            <text class="w150 tr fz26">单价：</text>
                            {{item.sellRate}}元
                        </view>
                    </view>
                </template>
            </div>
            <template v-if="servicesPrice.ZXFPrice > 0" v-for="item in servicesPrice.ZXF">
                <div class="flex_m plt25 border_t lh60">
                    <div class="flex1 ellipsis mr20">
                        <span class="w150 tr fz26">{{ unitFun(item.unit) || ''}}数：</span>
                        {{item.number}}
                    </div>
                    <div class="flex1 ellipsis">
                        <span class="w150 tr fz26">单价：</span>
                        {{item.sellRate}}元
                    </div>
                </div>
            </template>
        </div>
        <template v-if="type == 'out' && servicesPrice.ZZFWPrice>0">
            <div class="block_base mt30 pl20 pr20">
                <div class="flex_m plt25 lh60">
                    <div>增值服务费</div>
                    <div v-if="servicesPrice.ZXFPrice>0" class="flex_autosize ml20 flex_end ellipsis">
                        {{servicesPrice.ZZFWPrice}}元</div>
                </div>
                <template v-for="item in servicesPrice.ZZFW">
                    <div class="flex_m plt25 border_t lh60">
                        <div class="flex1 ellipsis mr20">
                            <span class="w150 tr fz26">{{item.feeValue}}：</span>
                            {{item.price}}元
                        </div>
                        <div class="flex1 ellipsis">
                            <span class="w150 tr fz26">{{ unitFun(item.unit) || ''}}数：</span>
                            {{changeUnitPriceNameFunC(item,item.unit)}}
                        </div>
                    </div>
                    <div class="flex_m plt25 lh60">
                        <div class="flex1 ellipsis">
                            <span class="w150 tr fz26">单价：</span>
                            ￥{{item.sellRate}}
                        </div>
                    </div>
                </template>
            </div>
        </template>
        <div v-if="servicesPrice.total" class="block_base wrap flex_lr_m mt30 lh60 pl20 pr20">
            <div>合计：</div>
            <div class="c-red">{{servicesPrice.total}}元</div>
        </div>
        <div class="block_base mt30 mb">
            <div class="flex_m lh100 mlr20">
                <span class="mr30 pr20 f666">备注</span>
                <div>{{parmsJson.remark}}</div>
            </div>
        </div>
        <div class="pfixed w100pc tc" v-if="!id">
            <van-button class="saveBtn" round :disabled="saveDisabled" @click="saveFun">确认{{type == 'in' ? '入':'出'}}库单
            </van-button>
        </div>
    </div>
</template>
<script>
    const { specsArr, warehousTypeArr, entryTable, outTable, outTypeArr, outPayArr, outTalkArr, rulesNameFun, changeUnitPriceNameFun, totalFun } = require('@/assets/js/warehouse.js')
    const API = require('/src/assets/js/apilist.js');
    export default {
        name: 'm_entry_detail',
        data() {
            return {
                type: '',//in：入库，out：出库
                actType: '',//空:(默认)新建 add:再下一单 edit:编辑
                id: '',//详情id
                parmsJson: {},
                warehousTypeArr,
                entryTable,
                outTable,
                outTypeArr,
                outPayArr,
                outTalkArr,
                servicesPrice: {
                    ZCFPrice: 0, //租仓费价格
                    ZXFPrice: 0, //装卸费价格
                    ZZFWPrice: 0,//其他增值服务合计
                    total: 0, //总费用
                    ZCF: [],//租仓费
                    ZXF: [],//装卸费
                    ZZFW: [],//其他增值服务
                }, //费用明细
                servicesPrice2: {},//没有明细时的计费,展示的是单价
                saveDisabled: false,
            };
        },
        mounted() {
            this.type = this.$route.query.type || ''
            this.id = this.$route.query.id || ''
            this.actType = this.$route.query.actType || ''
            if (this.id) {//详情
                this.getDetailFun(this.id)
            } else {//新建的再次确认进来的
                let parmsJson = localStorage.getItem('parmsJson')
                if (parmsJson) {
                    this.parmsJson = JSON.parse(parmsJson)
                } else {
                    console.log('下单成功后点击返回！');
                }
                this.getEnterPricFun()//获取计费
            }

        },
        methods: {
            //详情
            getDetailFun(id) {
                let self = this
                let isIn = self.type == 'in' ? true : false
                let api = isIn ? API.getById : API.outGetById
                this.$axios('get', api + '?id=' + id).then(res => {
                    if (res && res.code == '200') {
                        let inArr = res.data && res.data.commodityJson ? JSON.parse(res.data.commodityJson) : [] //入库单货品明细是快照
                        let outArr = res.data && res.data.depotStock ? res.data.depotStock : []
                        self.parmsJson = res.data
                        self.parmsJson['commodityJson'] = isIn ? inArr : outArr
                        self.getEnterPricFun()//获取计费
                    }
                })

            },
            //保存
            saveFun() {
                let self = this
                this.saveDisabled = true
                let parm = {} //保存的参数
                let api = null //保持接口地址
                let title = ''//成功后传的提示语
                let { commodityJson, depotName, isChecked, ...other } = this.parmsJson
                if (!this.actType || this.actType == 'add') {//新增的单或者是再来一单，清空id，只有编辑的时候保存保留id
                    other.id = ''
                }
                if (this.type == 'in') {//入库单
                    parm = {
                        ...other,
                        commodityJson: JSON.stringify(commodityJson)
                    }
                    if (this.actType == 'edit') { //修改
                        parm.creator = ''
                    }
                    api = API.entrySave
                    title = '入库单号：'
                } else if (this.type == 'out') {//出库单
                    other.valueAddedServices = other['valueAddedServices'] ? other['valueAddedServices'].join(',') : ''
                    other.payment = this.servicesPrice.total
                    delete other.creator
                    delete other.updator
                    parm = {
                        depotOut: { ...other },
                        depotStock: commodityJson
                    }
                    api = API.outSave
                    title = '出库单号：'
                }
                let contentType = this.type == 'in' ? 'application/x-www-form-urlencoded' : 'application/json'
                this.$axios('post', api, parm, 'data', contentType).then(res => {
                    if (res && res.code == '200') {
                        self.$toast('新建成功！', 'success');
                        localStorage.removeItem('parmsJson') //清空缓存
                        let inNo = res && res.data && res.data.depotEnterNo ? res.data.depotEnterNo : ''
                        let outNo = res && res.msg ? res.msg : ''
                        let orderNo = self.type == 'in' ? inNo : outNo
                        self.$router.push({ name: 'm_finished', params: { title: title + orderNo, actType: this.actType, type: '1' } });
                    }else{
                        self.$toast('出错了' + (res && res.msg ? res.msg : ''), 'error');
                    }
                })
            },
            //根据值展示正文
            getName(code, arr) {
                let text = ''
                arr.forEach(e => {
                    if (e.value == code) {
                        text = e.text
                    }
                });
                return text
            },
            //单位
            unitFun(num) {
                return rulesNameFun(num)
            },
            //增值服务根据不同单位渲染字段值
            changeUnitPriceNameFunC(item, type) {
                return changeUnitPriceNameFun(item, type)
            },
            //获取计费
            getEnterPricFun() {
                let self = this
                let newParms = JSON.parse(JSON.stringify(this.parmsJson))
                let commodityId = []//货品id
                let quantity = []//货品数量
                let depotEnterNo = [] //货品入库号（出库单用）
                let tableData = newParms.commodityJson
                if (tableData && tableData.length) {
                    for (let item of tableData) {
                        if (item.id && (item.enterNumber || item.outNumber)) {
                            commodityId.push(this.type == 'in' ? item.id : item.commodityId) //入库取‘id’ 出库取‘commodityId’
                            let number = item.specifications == '2' ? (this.type == 'in' ? item.enterNumber : item.outNumber) * item.number : (this.type == 'in' ? item.enterNumber : item.outNumber) //托要乘以单托件数
                            quantity.push(number)
                            if (this.type == 'out') {
                                depotEnterNo.push(item.depotEnterNo)
                            }
                        }
                    }
                }
                let obj = {
                    depotId: newParms.depotId || '',
                    number: newParms.totalPakages || '',
                    volume: newParms.totalVolume || '',
                    weight: newParms.totalWeight || '',
                    status: 1,//已启用的增值服务
                    commodityId: commodityId.length ? commodityId.join(',') : '',
                    quantity: quantity.length ? quantity.join(',') : '',
                }
                let inParams = {//入库费用调用不一样的参数
                    warehousingTime: newParms.warehousingTime || '',
                    storageDays: newParms.storageDays || '',
                    feeCode: 'ZCF,ZXF',
                }
                let outParams = {//出库费用调用不一样的参数
                    depotEnterNo: depotEnterNo.length ? depotEnterNo.join(',') : '',
                    takeTime: newParms.takeTime || '',
                    feeCode: 'ZCF,ZXF' + (newParms.valueAddedServices && newParms.valueAddedServices.length ? ',' + newParms.valueAddedServices.join(',') : ''),
                }
                let api = this.type == 'in' ? API.enterPricing : API.selectPricing
                let params = this.type == 'in' ? { ...obj, ...inParams } : { ...obj, ...outParams }
                self.$axios('get', api, params, 'params', 'application/json').then((res) => {
                    if (res && res.code == '200') {
                        let data = res.data
                        if (data.zcfFeeList || data.feeList) {
                            let ZCFArr = [],
                                ZXFArr = [],
                                zzArr = [];
                            for (let i = 0, arr = data.feeList; i < arr.length; i++) {
                                if (arr[i].feeType === 'ZCF') {
                                    ZCFArr.push(arr[i]);
                                } else if (arr[i].feeType === 'ZXF') {
                                    ZXFArr.push(arr[i]);
                                } else {
                                    zzArr.push(arr[i]);
                                }
                            }
                            let servicesPrice = {
                                ZCFPrice: data.zcfFeeList || 0,//租仓费价格
                                ZXFPrice: data.ZXF || 0,//装卸费价格
                                ZZFWPrice: data.totalAccretionFee || 0,//其他增值服务合计
                                total: data.totalFee.toFixed(2),//总费用
                                ZCF: self.type == 'in' ? data.feeList : ZCFArr,//租仓费
                                ZXF: self.type == 'in' ? data.zxfList : ZXFArr,//装卸费
                                ZZFW: zzArr,//其他增值服务
                            }//费用明细
                            console.log(servicesPrice);
                            self.servicesPrice = { ...servicesPrice }
                        } else {
                            self.servicesPrice2 = data
                        }
                    } else {
                        self.$toast2.show('出错了' + (res && res.msg ? res.msg : ''), 'error')
                    }
                })

            },
        }
    };
</script>
<style scoped>
    .flex1 {
        display: block;
    }

    .page-style {
        padding-bottom: 170px;
    }

    .pl50 {
        padding-left: 50px;
    }

    .mt15 {
        margin-top: 15px;
    }

    .table {
        border: 0px solid darkgray;
    }

    .t-tr {
        display: flex;
        width: 100%;
        justify-content: center;
        height: 80px;
        background-color: #fff;
        align-items: center;
        border-bottom: 1px solid #DBDCDB;
    }

    .bg-w {
        height: 65px;
    }

    .th,
    .td {
        flex: 1;
        justify-content: center;
        text-align: center;
    }

    .th {
        background-color: #E2EFF6;
        color: #666;
        display: flex;
        height: 65px;
        align-items: center;
    }

    .pfixed {
        position: fixed;
        bottom: 50px;
        z-index: 3
    }

    .w1,
    .w2 {
        width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .border_t {
        border-top: 1px solid #dcdcdc;
    }

    .lh60 {
        line-height: 60px;
    }

    .saveBtn {
        background-color: #007EC5;
        color: #fff;
    }

    .c-red {
        color: #007EC5;
    }
</style>